import { RECORDED_CONTENT_VIDEO_SOURCE } from "@my-scoot/exly-react-component-lib";
import {
  max_uploadable_file_size_in_mb,
  recorded_content_media_types,
} from "constants/recordedContent";
import {
  MIME_TYPE_TO_EXTENSION_MAP,
  TS_VIDEO_MIME_TYPE,
  video_format_keys,
} from "features/Common/modules/File/File.constants";
import { removeExtensionFromFileName } from "features/Common/modules/File/utils/File";
import useIsVdoCipherVideoHostingEnabled from "features/RecordedContent/modules/VdoCipher/hooks/useIsVdoCipherVideoHostingEnabled";
import {
  VDOCIPHER_VIDEO_MAX_FILE_SIZE_IN_GB,
  WISTIA_VIDEO_MAX_FILE_SIZE_IN_GB,
} from "features/RecordedContent/RecordedContent.constants";
import { getFileSize } from "utils/fileUtils";
import { getMediaTypeFromMIMEType } from "utils/recordedContentUtils/recordedContentUtils";
import { pluralise } from "utils/Utils";
import { UPLOAD_QUEUE_STATUS } from "../constants/MediaLibrary.constants";
import { IMediaLibrary } from "../MediaLibrary.interfaces";
import {
  getPreviewableUrl,
  getVdocipherDownloadUrl,
} from "./MediaLibrary.ApiCalls";

export const isVideoSourceWistia = (source: number) =>
  source === RECORDED_CONTENT_VIDEO_SOURCE.WISTIA;

const getExtensionFromMimeType = (mimeType: any) => {
  return (MIME_TYPE_TO_EXTENSION_MAP as { [key: string]: string })[mimeType];
};

const triggerDownloadFromUrl = async (url: string) => {
  const downloadLink = document.createElement("a");

  downloadLink.href = url;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  // Some browsers have built-in limitations on the number of simultaneous downloads they allow,
  // which can cause only a few files to be downloaded when you attempt to download many at once
  // so, adding 1 second dalay between requests
  await new Promise((resolve) => setTimeout(resolve, 1000));
};

const downloadAttachment = async (media: IMediaLibrary.IMedia) => {
  const { content_url: url, title } = media;
  const previewableUrl = await getPreviewableUrl(url);
  const actualContentResponse = await fetch(previewableUrl);
  const contentType = actualContentResponse.headers.get("Content-Type");
  const fileExtension = getExtensionFromMimeType(contentType);
  const blob = await actualContentResponse.blob();
  const blobUrl = window.URL.createObjectURL(blob);
  const anchor = document.createElement("a");

  anchor.href = blobUrl;
  anchor.download = `${removeExtensionFromFileName(title)}.${fileExtension}`;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  window.URL.revokeObjectURL(blobUrl);
};

export const downloadWistiaVideo = async (media: IMediaLibrary.IMedia) => {
  const { content_url: contentUrl } = media;
  const wistiaKey = getWistiaHashIdFromLink(contentUrl);
  const url = `https://api.wistia.com/v1/medias/${wistiaKey}.json`;
  const data = await fetch(url, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_WISTIA_TOKEN}`,
    },
  });
  const response = await data.json();
  const originalAsset = response.assets.find(
    (asset: any) => asset.type === "OriginalFile"
  );

  if (originalAsset) {
    const fileUrl: string = originalAsset.url;
    await triggerDownloadFromUrl(
      fileUrl.replace(".bin", `/${media.title}.mp4?disposition=attachment`)
    );
  }
};

export const downloadVdoCipherVideo = async (media: IMediaLibrary.IMedia) => {
  const downloadUrl = await getVdocipherDownloadUrl(media);
  await triggerDownloadFromUrl(downloadUrl);
};

const downloadVideo = (media: IMediaLibrary.IMedia) => {
  const { video_source: videoSource } = media;

  if (videoSource === RECORDED_CONTENT_VIDEO_SOURCE.WISTIA)
    return downloadWistiaVideo(media);

  return downloadVdoCipherVideo(media);
};

export const downloadMedia = async (media: IMediaLibrary.IMedia) => {
  if (!media) throw new Error("Please provide media to download");

  const { type } = media;

  if (type === recorded_content_media_types.video) return downloadVideo(media);

  return downloadAttachment(media);
};

export const getUploader = (
  uploadFunction: IMediaLibrary.IMediaUploader,
  params: IMediaLibrary.IMediaUploaderArgs
) =>
  new Promise((resolve, reject) => {
    uploadFunction({
      ...params,
      onSuccess: (result) => resolve(result),
      onError: (error) => reject(error),
      onAbort: () => reject(new Error("Upload aborted")),
    });
  });

export const isUploading = (status: number) =>
  status === UPLOAD_QUEUE_STATUS.UPLOADING;

export const isUploaded = (status: number) =>
  status === UPLOAD_QUEUE_STATUS.SUCCESS;

export const isPending = (status: number) =>
  status === UPLOAD_QUEUE_STATUS.PENDING;

export const isFailed = (status: number) =>
  status === UPLOAD_QUEUE_STATUS.FAILED;

export const formatRemainingTime = (seconds: number) => {
  seconds = Math.round(seconds);
  const days = Math.floor(seconds / (24 * 3600));
  seconds %= 24 * 3600;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  let timeText = "";

  if (days > 0) {
    timeText += `${days} ${pluralise("day", days)}`;
  }

  if (hours > 0) {
    timeText += ` ${hours} ${pluralise("hour", hours)}`;
  }

  if (minutes > 0) {
    timeText += ` ${minutes} ${pluralise("min", minutes)}`;
  }

  if (remainingSeconds > 0 || timeText === "") {
    // Include seconds if there's no other time unit
    timeText += ` ${remainingSeconds} ${pluralise("sec", remainingSeconds)}`;
  }

  return timeText + " left...";
};

export const isUploadingInProgress = (
  uploadQueue: IMediaLibrary.IRedux.IUploadItem[]
) => {
  return uploadQueue.some(
    (item) =>
      item.status === UPLOAD_QUEUE_STATUS.PENDING ||
      item.status === UPLOAD_QUEUE_STATUS.UPLOADING
  );
};

export const getTotalUploadedItems = (
  uploadQueue: IMediaLibrary.IRedux.IUploadItem[],
  mediaType: number | number[]
) => {
  return uploadQueue.filter(
    (item) =>
      item.status === UPLOAD_QUEUE_STATUS.SUCCESS &&
      (Array.isArray(mediaType)
        ? mediaType.includes(item.type)
        : item.type === mediaType)
  ).length;
};

export const getTotalFailedTasks = (
  uploadQueue: IMediaLibrary.IRedux.IUploadItem[]
) => {
  return uploadQueue.filter(
    (item) => item.status === UPLOAD_QUEUE_STATUS.FAILED
  ).length;
};

export const getTotalSuccessfulTasks = (
  uploadQueue: IMediaLibrary.IRedux.IUploadItem[]
) => {
  return uploadQueue.filter(
    (item) => item.status === UPLOAD_QUEUE_STATUS.SUCCESS
  ).length;
};

export const validateAttachmentFileSize = (file: File) => {
  const { fileSizeInMB } = getFileSize(file.size);
  return +fileSizeInMB < max_uploadable_file_size_in_mb;
};

export const getRetryDelayPromise = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms * 1000));

export const getWistiaHashIdFromLink = (url: string) => url?.split("/").pop();

export const getWistiaEmbedUrlFromWistiaLink = (url: string) =>
  url
    ? `https://fast.wistia.net/embed/iframe/${getWistiaHashIdFromLink(url)}`
    : null;

export const isTSVideoFileType = (file: File) =>
  file?.name?.endsWith(`.${video_format_keys.ts}`);

export const getFileMimeType = (file: File) => {
  const fileMimeType = file.type;

  if (fileMimeType) return fileMimeType;

  // Some TS video files don't have a MIME type in the file object,
  // so we check the file extension and return the MIME type manually if necessary.
  if (isTSVideoFileType(file)) return TS_VIDEO_MIME_TYPE;

  return fileMimeType;
};

export const useMediaFilesValidations = () => {
  const { isVdoCipherEnabled } = useIsVdoCipherVideoHostingEnabled();
  const videoFileSizeLimit = isVdoCipherEnabled
    ? VDOCIPHER_VIDEO_MAX_FILE_SIZE_IN_GB
    : WISTIA_VIDEO_MAX_FILE_SIZE_IN_GB;

  const validateVideoFileSize = (file: File) => {
    const { fileSizeInGB } = getFileSize(file.size);
    return +fileSizeInGB < videoFileSizeLimit;
  };

  const validateFiles = (files: File[]) => {
    const errorFlags = {
      image: {
        hasError: false,
        error: `Can’t upload. This image exceeds the maximum allowed size limit: ${max_uploadable_file_size_in_mb} MB`,
      },
      file: {
        hasError: false,
        error: `Can’t upload. This file exceeds the maximum allowed size limit: ${max_uploadable_file_size_in_mb} MB`,
      },
      video: {
        hasError: false,
        error: `Can’t upload. This video exceeds the maximum allowed size limit: ${videoFileSizeLimit} GB. Please compress the file and retry`,
      },
    };

    files.forEach((file) => {
      const mediaType = getMediaTypeFromMIMEType(file.type);
      const isImage = mediaType === recorded_content_media_types.image;
      const isVideo =
        mediaType === recorded_content_media_types.video ||
        isTSVideoFileType(file); // If the MIME type is not available, check if it's a TS video file

      if (isVideo) {
        if (!validateVideoFileSize(file)) errorFlags.video.hasError = true;
      } else {
        const isValidFileSize = validateAttachmentFileSize(file);

        if (!isValidFileSize) {
          if (isImage) {
            errorFlags.image.hasError = true;
          } else {
            errorFlags.file.hasError = true;
          }
        }
      }
    });

    return errorFlags;
  };

  const validateFile = (file: File) => {
    const mediaType = getMediaTypeFromMIMEType(file.type);
    const isImage = mediaType === recorded_content_media_types.image;
    const isVideo =
      mediaType === recorded_content_media_types.video ||
      isTSVideoFileType(file); // If the MIME type is not available, check if it's a TS video file
    let hasError = false;
    let error;

    if (isVideo) {
      const isValidVideoFileSize = validateVideoFileSize(file);

      if (!isValidVideoFileSize) {
        hasError = true;
        error = `Can’t upload. This video exceeds the maximum allowed size limit: ${videoFileSizeLimit} GB. Please compress the file and retry`;
      }
    } else {
      const isValidFileSize = validateAttachmentFileSize(file);

      if (!isValidFileSize) {
        hasError = true;

        if (isImage) {
          error = `Can’t upload. This image exceeds the maximum allowed size limit: ${max_uploadable_file_size_in_mb} MB`;
        } else {
          error = `Can’t upload. This file exceeds the maximum allowed size limit: ${max_uploadable_file_size_in_mb} MB`;
        }
      }
    }

    return { mediaType, hasError, error };
  };

  return {
    isVdoCipherEnabled,
    videoFileSizeLimit,
    validateFile,
    validateFiles,
    validateVideoFileSize,
  };
};
