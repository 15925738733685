export const whats_next_keys = {
  has_completed_all_steps: {
    key: "has_completed_all_steps",
  },
  has_offer_enabled: {
    key: "has_offer_enabled",
  },
  store_website_design: {
    key: "store_website_design",
  },
  has_live_listing: {
    key: "has_live_listing",
  },
  has_transacted: {
    key: "has_transacted",
  },
  plan_thank_you_visit_state: {
    key: "plan_thank_you_visit_state",
  },
  is_onboarded: {
    key: "is_onboarded",
  },
};
