import { IMAGE_OPTIMIZATION_CDN_URL } from "features/Common/modules/ExlyImage/constants/ExlyImage.constants";
import { ONBOARDING_ASSETS_URL } from "features/Onboarding/constants/Onboarding.constants";
import React from "react";

// marketing suits options , meta data
export const sales_marketing_suites_values = {
  WHATSAPP_MARKETING: "WhatsApp Marketing",
  EMAIL_MARKETING: "Email Marketing",
  LANDING_PAGES: "Landing Pages",
  ADS: "Ads",
  AFFILIATE_MARKETING: "Affiliate Marketing",
};

// marketing suite assets urls
export const sales_marketing_suite_base_url = `${ONBOARDING_ASSETS_URL}/questions/marketingSuite/`;
export const sales_marketing_suite_icons_base_url = `${IMAGE_OPTIMIZATION_CDN_URL}/onboarding/infoIcons/`;

// marketing suite options config
export const sales_marketing_suites_options = [
  {
    value: sales_marketing_suites_values.WHATSAPP_MARKETING,
    label: "WhatsApp Marketing",
    description:
      "Create, send and measure Whatsapp campaigns on custom  bulk lists or single customer.",
    img: `${sales_marketing_suite_base_url}whatsapp.svg`,
    primaryColor: "#60B139",
  },
  {
    value: sales_marketing_suites_values.EMAIL_MARKETING,
    label: "Email Marketing",
    description:
      "Create, send & measure your email marketing efforts and launch rule based drip campaigns.",
    img: `${sales_marketing_suite_base_url}email.svg`,
    primaryColor: "#6456C5",
  },
  {
    value: sales_marketing_suites_values.LANDING_PAGES,
    label: "Landing Pages",
    description:
      "Deploy beautiful sales pages designed for engagement & conversion in just a few clicks.",
    img: `${sales_marketing_suite_base_url}landing_pages.svg`,
    primaryColor: "#307BD3",
  },
  {
    value: sales_marketing_suites_values.ADS,
    label: "Ads",
    description:
      "Setup, manage and measure your Meta ad campaigns from one common dashboard.",
    img: `${sales_marketing_suite_base_url}ads.svg`,
    primaryColor: "#AE51C6",
  },
  {
    value: sales_marketing_suites_values.AFFILIATE_MARKETING,
    label: "Affiliate Marketing",
    description:
      "Create, manage and share UTM enabled payment links to track sales from various sources.",
    img: `${sales_marketing_suite_base_url}affiliate.svg`,
    primaryColor: "#DDA800",
  },
];

// first element from marketing suite options
export const [first_sales_marketing_suite_option] =
  sales_marketing_suites_options;

// marketing suite icons
export const sales_marketing_suite_icons = {
  AISENSY: `${sales_marketing_suite_icons_base_url}aisensy.svg`,
  INTERAKT: `${sales_marketing_suite_icons_base_url}interakt.svg`,
  DIALOG: `${sales_marketing_suite_icons_base_url}dialog.svg`,
  WATI: `${sales_marketing_suite_icons_base_url}wati.svg`,
  MAIL_CHIMP: `${sales_marketing_suite_icons_base_url}mail_chimp.svg`,
  MAILER: `${sales_marketing_suite_icons_base_url}mailer.svg`,
  GET_RESPONSE: `${sales_marketing_suite_icons_base_url}get_response.svg`,
  SEND_GRID: `${sales_marketing_suite_icons_base_url}send_grid.svg`,
  SYSTEM_IO: `${sales_marketing_suite_icons_base_url}system.svg`,
  WORDPRESS: `${sales_marketing_suite_icons_base_url}wordpress.svg`,
  WIX: `${sales_marketing_suite_icons_base_url}wix.svg`,
  CLICK_FUNNELS: `${sales_marketing_suite_icons_base_url}click_funnels.svg`,
  HUB_SPOT: `${sales_marketing_suite_icons_base_url}hubspot.svg`,
  FB_META: `${sales_marketing_suite_icons_base_url}meta.svg`,
  CANVA: `${sales_marketing_suite_icons_base_url}canva.svg`,
  TAP_AFFILIATE: `${sales_marketing_suite_icons_base_url}tap_affiliate.svg`,
  POST_AFFILIATE: `${sales_marketing_suite_icons_base_url}post_affiliate.svg`,
  ZOHO: `${sales_marketing_suite_icons_base_url}zoho.svg`,
};

// option wise information config for marketing suite
export const sales_marketing_suites_wise_info_config = {
  [sales_marketing_suites_values.WHATSAPP_MARKETING]: {
    title: <>Automate your WhatsApp campaigns like a pro!</>,
    desktop_right_gradient: { primary: "#F6FCF5", secondary: "#E6F5E2" },
    desktop_right_primary: "#60B139",
    description_config_desktop: {
      video_url: "https://www.youtube-nocookie.com/watch?v=_1FhaVYTyNQ",
      thumbnail_url: `/onboarding/whatsapp_marketing_onboarding.png?format=auto&width=500`,
      highlights: [
        "Auto Campaigns",
        "Scheduled Texts",
        "Targeted Content",
        "Mass Messaging",
        "Data Insights",
        "Unified Dashboard",
      ],
      icons: [
        sales_marketing_suite_icons.WATI,
        sales_marketing_suite_icons.INTERAKT,
        sales_marketing_suite_icons.AISENSY,
        sales_marketing_suite_icons.DIALOG,
      ],
    },
  },
  [sales_marketing_suites_values.EMAIL_MARKETING]: {
    title: <>Automate your email sequences for maximum impact!</>,
    desktop_right_gradient: { primary: "#F4F2FC", secondary: "#E7E2F5" },
    desktop_right_primary: " #6456C5",
    description_config_desktop: {
      video_url: "https://www.youtube-nocookie.com/watch?v=ERvuqDL4AXw",
      thumbnail_url: `/onboarding/marketing_onboarding.png?format=auto&width=500`,
      highlights: [
        "Email Design",
        "Engagement Stats",
        "Email Drips",
        "Timed Emails",
        "Lead Growth",
      ],
      icons: [
        sales_marketing_suite_icons.MAIL_CHIMP,
        sales_marketing_suite_icons.SEND_GRID,
        sales_marketing_suite_icons.GET_RESPONSE,
        sales_marketing_suite_icons.MAILER,
      ],
    },
  },
  [sales_marketing_suites_values.LANDING_PAGES]: {
    title: <>Design high-converting sales pages in minutes!</>,
    desktop_right_gradient: { primary: "#F4FAFF", secondary: "#EBF9FF" },
    desktop_right_primary: "#286399",
    description_config_desktop: {
      video_url: "https://www.youtube-nocookie.com/watch?v=iWNhcI0PJPg",
      thumbnail_url: `/onboarding/sales_pages_onboarding.png?format=auto&width=500`,
      highlights: [
        "Max ROI",
        "Boost Performance",
        "Strong CTAs",
        "Increase Conversions",
        "Track Visitors",
        "Analyze Performance",
      ],
      icons: [
        sales_marketing_suite_icons.SYSTEM_IO,
        sales_marketing_suite_icons.WORDPRESS,
        sales_marketing_suite_icons.WIX,
        sales_marketing_suite_icons.CLICK_FUNNELS,
      ],
    },
  },
  [sales_marketing_suites_values.ADS]: {
    title: <>Scale your lead generation effortlessly with our ad manager!</>,
    desktop_right_gradient: { primary: "#FFF7FE", secondary: "#FEEEFD" },
    desktop_right_primary: " #884C84",
    description_config_desktop: {
      video_url: "https://www.youtube-nocookie.com/watch?v=XEbvUOGo6FY",
      thumbnail_url: `/onboarding/ads_onboarding.png?format=auto&width=500`,
      highlights: [
        "High ROI",
        "Conversion Boost",
        "Creative Design",
        "Live Monitoring",
        "ROI Boost",
      ],
      icons: [
        sales_marketing_suite_icons.FB_META,
        sales_marketing_suite_icons.HUB_SPOT,
        sales_marketing_suite_icons.CANVA,
      ],
    },
  },
  [sales_marketing_suites_values.AFFILIATE_MARKETING]: {
    title: <>Monitor Sales Across Pages!</>,
    desktop_right_gradient: { primary: "#FFFBED", secondary: "#FFF8DF" },
    desktop_right_primary: "#E0AA00",
    description_config_desktop: {
      video_url: "https://www.youtube-nocookie.com/watch?v=uWTaJe61F9Y",
      thumbnail_url: `/onboarding/affiliate_onboarding.png?format=auto&width=500`,
      highlights: [
        "Track Links",
        "Performance Data",
        "Simple Payouts",
        "Affiliate Stats",
      ],
      icons: [
        sales_marketing_suite_icons.TAP_AFFILIATE,
        sales_marketing_suite_icons.POST_AFFILIATE,
        sales_marketing_suite_icons.ZOHO,
      ],
    },
  },
};
